<template>
  <v-dialog v-model="dialog" max-width="700px">
    <v-card v-if="src != null">
      <v-img :src="src" aspect-ratio="1" contain></v-img>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    src: null
  }),
  created() {
    this.$eventBus.$on("ImageDialog", params => {
      this.src = params.state ? params.src : null
      this.dialog = params.state
    })
  }
}
</script>
